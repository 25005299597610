<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="康复方案详情"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">康复方案</h3></Divider>
        <p  style="white-space: pre-line">{{rehabilitationFile.des}}</p>
        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">训练教程</h3></Divider>
        <video controls="controls"  width='100%;' padding-top='-6px' id="videoInfo" :src="rehabilitationFile.url">
            <source type="video/mp4"/>
        </video>
        <RLFooter/>
    </div>
</template>

<script>

    // import {Button} from 'vant';
    import {getRehabilitationFile} from '@/api/rehabilitation';

    export default {
        name: 'QueIdx',
        components: {

        },
        data(){
            return {
                active:0,
                rehabilitationFile:{},
                nodata:false,
                activeTab: 0,
                url:''
            };
        },
        computed:{

        },
        methods:{
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;

                let id=query.id;
                console.log(id)
                getRehabilitationFile({id:id}).then((response) => {
                    if(response.code==200 ){

                        this.rehabilitationFile=response.data;
                    }
                });
            },
            showQue(idx){
                this.$router.push({path: '/que/'+idx.id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },

        }
        ,
        created() {
            /*初始化列表*/
            this.initidx();
            // let videoUrl = this.$route.params.url
            // let query = this.$route.query;
            //
            // this.url=query.url;
            // console.log(this.url)
            // let videos = document.getElementById('videoInfo');
            // videos.src = this.url;

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
